export const experience = [
 {
  type: 'School',
  year: '2014-2019',
  place: 'JCT',
  text: `
      <ul class="list-disc pl-4">
      <li>B.Sc., Industrial Engineering and Management.</li>
      </ul
  
      `,
 },
 {
  type: 'School',
  year: '2018-2019',
  place: 'IDF Officers Training School (Bahad 1)',
  text: `
      <ul class="list-disc pl-4">
      <li> IDF Officers Course, Officers Training School .</li>
      </ul
     `,
 },
 {
  type: 'work',
  year: '2016–2017',
  place: 'SPOKESMAN OF THE STUDENT UNION, JCT ',
  text: `
      
      <ul class="list-disc pl-4">
      <li>Managed the digital assets, worked with the media to represent the students.</li>
    
    </ul>
     `,
 },
 {
  type: 'work',
  year: '2017–2018',
  place: 'CHAIRMAN OF THE STUDENT UNION, JCT ',
  text: `
  
      <ul class="list-disc pl-4">
      <li>Managed the union workers and their tasks, including multiple simultaneous projects.</li>
      <li>Worked with academic departments, municipalities, associations and the Knesset.</li>
    </ul>
    `,
 },
 {
  type: 'work',
  year: '2019–2022',
  place: 'FULL-STACK DEVELOPER, HATAL – IDF',
  text: `
      <ul class="list-disc pl-4">
    <li>
      Captain rank. Completed various projects for the IDF’s Ground Forces Tech Brigade (Hatal) using
      React.js (frontend) and Node.js (backend):
    </li>
    <li>
      Developed an online system to support military vehicle testers throughout the testing process.
      The system is in use by hundreds of users. Technologies: React, Node.JS, Express, PostgreSQL,
      Knex. Styled-Components for design.
    </li>
    <li>
      Created a cloud app to make the entire security standard files of the Quality Assurance &
      Security Department accessible (sort, search and unify). Technologies: React, Node.JS, Express,
      MongoDB, Azure Storage, Azure AD. Styled-Components for design.
    </li>
  </ul>
    `,
 },
 {
  type: 'work',
  year: '2022-TODAY',
  place: 'FULL-STACK DEVELOPER, SAPIENS',
  text: `
      <ul  class="list-disc pl-4">
      <li>Part of the Digital suit: worked in the LiveChat team to maintain and improve the live-chat product.
      <li>Used React.js, Node.js, Redux, Socket.io, MUI. Used jest and react-testing library for testing.
      </li>
      <li>
     Working in a global environment with Israeli and Indians teams.
      </li>
      
      </ul
      
    `,
 },
];
