import cx from "classnames";
export const Title = ({ text, backGround }) => {
  console.log(backGround);
  return (
    <div
      className={cx("py-3 sticky w-full text-3xl font-semibold text-white top-0  ", {
        "bg-customPurple1": backGround,
      })}>
      {text}
    </div>
  );
};
