import { NavBar } from "../components/NavBar";

export const Layout = ({ children }) => {
  return (
    <div className="h-full flex flex-col justify-between sm:flex-row-reverse sm">
      {children}

      <NavBar />
    </div>
  );
};
