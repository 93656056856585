import { MdHome, MdMessage, MdPerson, MdSchool, MdWork } from "react-icons/md";
import { NavLink } from "./utils/NavLink";

export const NavBar = () => {
  return (
    <div className="sm:h-screen sticky bottom-0 bg-customPurple border border-l-0 sm:border-t-0 border-r-0 sm:border-r border-b-0 border-indigo-300  ">
      <div className="flex sm:flex-col w-full h-full justify-around items-center py-3 sm:py-0">
        <NavLink icon={<MdHome className="h-7 w-7" />} path={"/"} />
        <NavLink icon={<MdPerson className="h-7 w-7" />} path={"/about"} />
        <NavLink icon={<MdWork className="h-7 w-7" />} path={"/experience"} />
        {/* <NavLink icon={<MdSchool className="h-7 w-7" />} path={"/school"} /> */}
        <NavLink icon={<MdMessage className="h-7 w-7" />} path={"/contact"} />
      </div>
    </div>
  );
};
