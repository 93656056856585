export const TextAreaInput = ({ placeHolder, label, type, data, setData, name, value }) => {
  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setData({ ...data, [name]: value });
  };
  return (
    <textarea
      placeholder={placeHolder}
      className="resize-none w-full h-64 bg-gray-400 rounded-2xl p-2 py-3 pl-4 placeholder-white  m-2 outline-none focus:outline-none"
      onChange={(e) => handleChange(e)}
      name={name}
      value={value}
      type={type}
    />
  );
};
