import { Layout } from "../layout";
import { NavBar } from "./NavBar";
import { TextAreaInput } from "./utils/TextAreaInput";
import { TextInput } from "./utils/TextInput";
import axios from "axios";
import { useState } from "react";
export const ContactMe = () => {
  const [data, setData] = useState({});
  let url = "https://sheet.best/api/sheets/ac92a8f2-1e95-40ed-983d-5e05c040f2cf";
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("data", data);
    axios.post(url, data).then((res) => {
      setData({
        name: "",
        phone: "",
        email: "",
        message: "",
      });
      console.log(res.data);
    });
    console.log(data);
  };
  return (
    <Layout>
      <div className="flex flex-col items-center w-full">
        <div className="py-3  w-full text-3xl font-semibold text-white  ">Get In Touch</div>
        <div className="w-full px-5">
          <div className="sm:flex  sm:my-3">
            <TextInput
              placeHolder={"Your Name"}
              type="text"
              data={data}
              setData={setData}
              name="name"
              value={data.name}
            />
            <TextInput
              placeHolder={"Email"}
              type="email"
              data={data}
              setData={setData}
              name="email"
              value={data.email}
            />
          </div>
          <div className="sm:flex flex-col   sm:my-3">
            <TextInput
              placeHolder={"Phone Number"}
              type="number"
              data={data}
              setData={setData}
              name="phone"
              value={data.phone}
            />
            <div className="m-2">
              <TextAreaInput
                placeHolder="Message"
                data={data}
                setData={setData}
                name="message"
                value={data.message}
              />
            </div>
          </div>
        </div>
        <div
          className="text-white text-xl bg-customRed cursor-pointer rounded-full p-3 w-1/2 sm:w-1/3 mt-1 text-center "
          onClick={(e) => handleSubmit(e)}>
          Send Message
        </div>
      </div>
    </Layout>
  );
};
