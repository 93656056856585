export const TextInput = ({ placeHolder, label, type, data, setData, name, value }) => {
  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setData({ ...data, [name]: value });
  };
  return (
    <div className=" sm:w-1/2 px-3">
      <label>{label}</label>
      <input
        placeholder={placeHolder}
        className="bg-gray-400 rounded-full p-2 py-3 pl-4 placeholder-white w-full m-2 outline-none focus:outline-none"
        autoComplete="false"
        onChange={(e) => handleChange(e)}
        name={name}
        value={value}
        type={type}
      />
    </div>
  );
};
