import cx from "classnames";
import { Link, useLocation } from "react-router-dom";
export const NavLink = ({ icon, path }) => {
  const router = useLocation();
  return (
    <div className="sm:p-5">
      <Link
        to={path}
        className={cx(
          "outline-none focus:outline-none cursor-pointer text-white hover:text-gray-400",
          {
            "text-indigo-300 hover:text-indigo-300": path === router.pathname,
          }
        )}>
        {icon}
      </Link>
    </div>
  );
};
