import { MdSchool, MdWork } from 'react-icons/md';
import { experience } from '../data';
import { Layout } from '../layout';
import { Tile } from './utils/Tile';

export const Experience = () => {
  return (
    <Layout>
      <div className="flex flex-col  ">
        <div className="py-3 sticky w-full text-3xl font-semibold text-white top-0 bg-customPurple  ">
          Experience
        </div>
        <div className=" w-full bg-customPurple overflow-scroll h-full">
          <div className=" p-4 rounded-xl mt-3 flex justify-around  flex-col-reverse">
            {experience.map((item, i) => (
              <Tile
                key={i}
                year={item.year}
                place={item.place}
                text={item.text}
                icon={item.type === 'work' ? <MdWork /> : <MdSchool />}
              />
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};
