import { Markup } from 'interweave';

export const Tile = ({ icon, year, place, text }) => {
  return (
    <div className="bg-customPurple1  rounded-xl text-white sm:mr-2 p-4 mb-4 sm:mb-0">
      <div className="border-customRed1 border-l  ">
        <div className=" p-2 text-customRed1 text-2xl">{icon}</div>
        <div className="pl-4">
          <div className="flex flex-col items-start pb-2">
            <div className="text-sm font-extralight">{year}</div>
            <div className="text-lg font-bold">{place}</div>
          </div>
          <Markup className="text-left" content={text} />
        </div>
      </div>
    </div>
  );
};
