import { ProficiencyLine } from './utils/ProficiencyLine';
import { NavBar } from './NavBar';
import me from '../assets/me.png';
import cv from '../assets/Avner_Russler_CV.pdf';
import { Layout } from '../layout';
import { Title } from './utils/Title';
import { FaReact, FaNodeJs } from 'react-icons/fa';
import { SiMongodb, SiPostgresql, SiTypescript } from 'react-icons/si';
export const About = () => {
  return (
    <Layout>
      <div className="flex w-full flex-col items-center  justify-center">
        <Title backGround text=" About Me"></Title>
        <div className="flex items-center flex-col sm:flex-row overflow-hidden">
          <img src={me} alt="me" className="sm:w-64 sm:h-64 h-32 w-h-32 rounded-full " />
          <div className=" flex flex-col  justify-between p-4 m-4 rounded-xl">
            <div className="flex flex-col   items-center ">
              <div className="text-white text-xl sm:my-3 mb-3 ">
                <p>Hi 🙋🏼‍♂️, Im Avner Russler</p>
                <p>a Full-Stack developer from Ashdod, Israel.</p>
              </div>
            </div>

            <div className="sm:pl-3 px-2  sm:mt-0  mb-2  flex flex-col  w-full justify-around ">
              <ProficiencyLine label="React.js" color={'#61dafb'} level={80} icon={<FaReact />} />
              <ProficiencyLine label="Node.js" color={'#026e01'} level={77} icon={<FaNodeJs />} />
              <ProficiencyLine
                label="TypeScript"
                color={'#3178c6'}
                level={65}
                icon={<SiTypescript />}
              />
              <ProficiencyLine label="mongoDB" color={'#11934f'} level={71} icon={<SiMongodb />} />
              <ProficiencyLine
                label="postgreSQL"
                color={'#326790'}
                level={63}
                icon={<SiPostgresql />}
              />
            </div>
            <a
              href={cv}
              download="cv.pdf"
              className="text-white text-xl bg-customRed cursor-pointer rounded-full p-3   mt-1 text-center ">
              Download My CV
            </a>
          </div>
        </div>
      </div>
    </Layout>
  );
};
