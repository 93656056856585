import { SocialLink } from "./utils/SocialLink";
import { IoLogoFacebook, IoLogoGithub, IoLogoLinkedin, IoMdMail } from "react-icons/io";
import me from "../assets/me.png";
import { NavBar } from "./NavBar";
import { Link } from "react-router-dom";
import { Layout } from "../layout";
export const Home = () => {
  return (
    <Layout>
      <div></div>
      <div className=" flex flex-col justify-center items-center w-full overflow-hidden  ">
        <img src={me} alt="me" className=" w-64 h-64 rounded-full " />
        <div className=" text-white font-bold text-4xl py-3">Avner Russler</div>
        <div className="text-white font-light py-2">Full-Stack Developer </div>
        <div className="flex pb-4">
          <SocialLink link={"https://www.facebook.com/AvneRussler/"} icon={<IoLogoFacebook />} />
          <SocialLink link={"mailto:avnerdvd@gmail.com"} icon={<IoMdMail />} />
          <SocialLink link={"https://github.com/Avnerussler"} icon={<IoLogoGithub />} />
          <SocialLink link={"https://www.linkedin.com/in/avnerussler/"} icon={<IoLogoLinkedin />} />
        </div>
        <div>
          <Link to="/contact">
            <div className="text-white text-xl bg-customRed cursor-pointer rounded-full py-3 w-40 text-center">
              Hire Me
            </div>
          </Link>
        </div>
      </div>
    </Layout>
  );
};
