import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import "./App.css";
import { About } from "./components/About";
import { Home } from "./components/Home";
import { Experience } from "./components/Experience";
import { ContactMe } from "./components/ContactMe";
import { School } from "./components/School";
function App() {
  return (
    <div className="App">
      <Router>
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route path="/contact">
            <ContactMe />
          </Route>
          <Route path="/school">
            <School />
          </Route>
          <Route path="/experience">
            <Experience />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
