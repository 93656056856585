import { useEffect, useState } from 'react';

export const ProficiencyLine = ({ label, level, color, icon }) => {
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      if (counter < level) {
        setCounter(counter + 1);
      }
    }, 10);
  }, [counter]);
  // console.log("counter", counter);
  // console.log(level);
  return (
    <div className="py-2 w-full">
      <div className="flex items-center" style={{ color: `${color}` }}>
        <div className="mr-1">{icon}</div>
        <div className="text-white text-base font-extralight text-left ">{label}</div>
      </div>

      {/* <div className="text-white text-right">{`${counter}%`}</div> */}
      <div
        style={{ width: `${level}%`, backgroundColor: `${color}` }}
        className=" p-0.5 path rounded-full"></div>
    </div>
  );
};
